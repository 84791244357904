<template>
	<div class="caculate_centent">
		<div class="question-title">大剂量向导计算</div>
		<van-form @submit="submit()">
			<van-field
				v-model="BasicInformation.newBloodGlucose"
				required
				name="当前血糖值(mmol/L)"
				type="number"
				label="当前血糖值(mmol/L)"
				placeholder="请填写当前血糖值"
				maxlength="5"
				autocomplete="off"
				:rules="[{ required: true, message: '请填写当前血糖值', trigger: 'submit' }]"
			/>
			<van-field
				v-model="BasicInformation.targetBloodGlucose"
				required
				name="目标血糖值(mmol/L)"
				type="number"
				label="目标血糖值(mmol/L)"
				placeholder="请填写目标血糖值"
				maxlength="5"
				autocomplete="off"
				:rules="[{ required: true, message: '请填写目标血糖值', trigger: 'submit' }]"
			/>
			<van-field
				v-model="BasicInformation.iSF"
				required
				name="敏感系数ISF(mmol/L*U)"
				type="number"
				label="敏感系数ISF(mmol/L*U)"
				placeholder="请填写敏感系数ISF"
				maxlength="5"
				autocomplete="off"
				:rules="[{ required: true, message: '请填写敏感系数ISF', trigger: 'submit' }]"
			/>
			<van-field
				v-model="BasicInformation.carbohydrateGrammage"
				required
				name="碳水化合物克数(grams)"
				type="number"
				label="碳水化合物克数(grams)"
				placeholder="请填写碳水化合物克数"
				maxlength="5"
				autocomplete="off"
				:rules="[{ required: true, message: '请填写碳水化合物克数', trigger: 'submit' }]"
			/>
			<van-field
				v-model="BasicInformation.iCR"
				required
				name="碳水系数ICR(grams/U)"
				type="number"
				label="碳水系数ICR(grams/U)"
				placeholder="请填写碳水系数ICR"
				maxlength="5"
				autocomplete="off"
				:rules="[{ required: true, message: '请填写碳水系数ICR', trigger: 'submit' }]"
			/>
			<van-field
				v-model="BasicInformation.activeInsulin"
				required
				name="活性胰岛素(U)"
				type="number"
				label="活性胰岛素(U)"
				placeholder="请填写活性胰岛素"
				maxlength="5"
				autocomplete="off"
				:rules="[{ required: true, message: '请填写活性胰岛素', trigger: 'submit' }]"
			/>
			<div class="large_dose_button">
				<!-- <van-button @click="reset()" round type="info" color="#ccc">刷新</van-button> -->
				<van-button native-type="submit" round type="info" color="#4B72FE">估算总量</van-button>
			</div>
		</van-form>
		<div class="large_dose" v-if="largeDose">计算结果：{{ largeDose }}</div>
	</div>
</template>

<script>
import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
export default {
	name: 'AddCsiiLifestyleList',
	data() {
		return {
			BasicInformation: {
				//当前血糖值
				newBloodGlucose: '',
				//目标血糖值
				targetBloodGlucose: '',
				//敏感系数
				iSF: '',
				//碳水化合物克数
				carbohydrateGrammage: '',
				//碳水系数
				iCR: '',
				//活性胰岛素
				activeInsulin: ''
			},
			largeDose: null,
			largeDoseShow: false
		};
	},
	mounted() {
		// 计算input高度
		/* var height = document.querySelector('.van-field__label').clientHeight;
		var alist = document.getElementsByClassName('van-field__control');
		if (alist) {
			for (var idx = 0; idx < alist.length; idx++) {
				var mya = alist[idx];
				mya.style.height = height + 'px';
			}
		} */
	},
	methods: {
		submit() {
			let newBloodGlucose = this.BasicInformation.newBloodGlucose;
			let targetBloodGlucose = this.BasicInformation.targetBloodGlucose;
			let iSF = this.BasicInformation.iSF;
			let carbohydrateGrammage = this.BasicInformation.carbohydrateGrammage;
			let iCR = this.BasicInformation.iCR;
			let activeInsulin = this.BasicInformation.activeInsulin;
			let bloodGlucose = (newBloodGlucose - targetBloodGlucose) / iSF;
			let carbohydrate = carbohydrateGrammage / iCR;
			let largeDose = bloodGlucose + carbohydrate - activeInsulin
			console.log(largeDose.toString().split("."));
			if(largeDose.toString().split(".")[1] && largeDose.toString().split(".")[1].length>2){
				this.largeDose = largeDose.toFixed(2);
			}else{
				this.largeDose = largeDose
			}
			console.log(bloodGlucose);
			console.log(carbohydrate);
			console.log(activeInsulin);
			console.log(bloodGlucose + carbohydrate - activeInsulin);
			console.log(this.largeDose);
		},
		reset(){
			location.reload();
		}
	}
};
</script>

<style scoped="scoped">
/deep/.van-field__label {
	width: 11rem;
}
.van-button {
	margin-top: 20px;
}
.question-title {
	font-size: 1rem;
	padding: 0 10px;
	height: 2.875rem;
	line-height: 2.875rem;
	border-bottom: 1px dashed #ddd;
	border-top: 1px dashed #ddd;
	background-color: #f8f9fc;
}
/deep/.van-field__label span {
	white-space: pre-line;
}
.large_dose {
	text-align: center;
	padding: 20px 0;
	font-size: 18px;
}
.caculate_centent {
	background-color: #efefef;
}
.large_dose_button{
	margin:0 20px;
	text-align: center;
}
.large_dose_button button{
	/* width: calc(50% - 10px); */
	width: 100%;
}
.large_dose_button button:first-child{
	margin-right: 20px;
}
</style>
